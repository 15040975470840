import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Navbar, Nav, NavItem, NavLink, Row, Col, Card, Button, Container, Badge, CardBody, ListGroup, ListGroupItem } from 'reactstrap';
import commonApi from '../utils/api';
import moment from 'moment';

const TrackShipment = (props) => {
    const location = useLocation();
    const [expandedTracking, setExpandedTracking] = useState(false);
    const [trackingInfo, setTrackingInfo] = useState([]);
    const [pod, setPod] = useState('');
    const [inputChecked, setInputChecked] = useState('lr_nos');
    const [inputValue, setInputValue] = useState('');
    const [awbNoParam, setAwbNoParam] = useState(null);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const awbNo = queryParams.get('awb_no');
        if (awbNo) {
            setInputChecked('awb_no');
            setInputValue(awbNo);
            setAwbNoParam(awbNo);
        }
    }, [location.search]);
    useEffect(() => {
        if (inputChecked === 'awb_no' && awbNoParam) {
            onTracking();
        }
    }, [awbNoParam])
    const handleRadioChange = (e) => {
        if (['lr_nos', 'mobile_no', 'awb_no'].includes(e.target.value)) {
            setInputChecked(e.target.value);
        } else {
            setInputValue(e.target.value);
        }
    };

    const onTracking = (id) => {
        if (inputValue) {
            setExpandedTracking(false);
            let postReq = {};
            if (inputChecked === 'lr_nos') {
                postReq = { lr_nos: inputValue };
            } else if (inputChecked === 'mobile_no') {
                postReq = { mobile_no: inputValue };
            } else if (inputChecked === 'awb_no') {
                postReq = { awb_no: inputValue };
            }

            commonApi.post('/orders/tracking', postReq)
                .then((response) => {
                    setExpandedTracking(true);
                    if (response?.data?.s === 1) {
                        setTrackingInfo(response?.data?.data);
                        setPod(response?.data?.podData);
                    }
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                });
        } else {
            // Only show alert if awbNoParam is NOT present (i.e., manual click without input)
            if (!awbNoParam) {
                alert('Please Enter LR/Mobile number');
            }
        }
    };


    return (
        <>
            <div className="main-container">
                <Navbar expand="sm">
                    <Nav className="justify-content-center w-100 position-relative flex-row" navbar >
                        <NavItem className="tracking-logo">
                            <NavLink href="#" className='p-0'>
                                <img src="../img/logo.svg" alt="tracking" className="img-fluid" width="60" />
                            </NavLink>
                        </NavItem>
                    </Nav>
                </Navbar>
                <div className="container-fluid">
                    <Row className="align-items-center">
                        <Col sm={6} className='m-auto text-center'>
                            <div className="p-3 pt-md-5">
                                <h3>Order Tracking Made Easy</h3>
                                <p>Follow Your Orders As They Reach You</p>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="container-fluid mb-3">
                    <div className="mx-2 mx-md-0 pb-5">
                        <div id="track-shipment" className="ordertrackSearch">
                            <div style={{ textAlign: "center", width: "820px", maxWidth: "100%", margin: "auto", padding: "0.5rem", fontFamily: "RalewayThin,Helvetica, sans-serif" }}>
                                <h1 style={{ display: "none", color: "#3F3D56", fontFamily: "Noto Sans, Arial, sans-serif", fontSize: "35px", margin: "0px" }}>Track Your Order</h1>
                                <div class="mx-auto">
                                    <div style={{ display: "flex", justifyContent: "center", flexWrap: "wrap", margin: "1rem 0 0.5rem 0" }}>
                                        <fieldset style={{ minWidth: "0", padding: "0", margin: "0", border: "0" }}>
                                            <div style={{ marginRight: "1.5rem" }}>
                                                <input
                                                    type="radio"
                                                    id="lrNoRadio"
                                                    name="radiodShadow"
                                                    checked={inputChecked === 'lr_nos'}
                                                    value="lr_nos"
                                                    style={{ boxSizing: "border-box", padding: "0" }}
                                                    onChange={(e) => handleRadioChange(e)}
                                                />
                                                <label htmlFor="lrNoRadio" className="ms-1" style={{ display: "inline-block", marginBottom: "0.5rem" }}>LR No.</label>
                                            </div>
                                        </fieldset>
                                        <fieldset style={{ minWidth: "0", padding: "0", margin: "0", border: "0" }}>
                                            <div style={{ marginRight: "1.5rem" }}>
                                                <input
                                                    type="radio"
                                                    id="awbRadio"
                                                    name="radiodShadow"
                                                    checked={inputChecked === 'awb_no'}
                                                    value="awb_no"
                                                    style={{ boxSizing: "border-box", padding: "0" }}
                                                    onChange={(e) => handleRadioChange(e)}
                                                />
                                                <label htmlFor="awbRadio" className="ms-1" style={{ display: "inline-block", marginBottom: "0.5rem" }}>AWB No.</label>
                                            </div>
                                        </fieldset>
                                        <fieldset style={{ minWidth: "0", padding: "0", margin: "0", border: "0" }}>
                                            <div style={{ marginRight: "1.5rem" }}>
                                                <input
                                                    type="radio"
                                                    id="mobileRadio"
                                                    name="radiodShadow"
                                                    checked={inputChecked === 'mobile_no'}
                                                    value="mobile_no"
                                                    style={{ boxSizing: "border-box", padding: "0" }}
                                                    onChange={(e) => handleRadioChange(e)}
                                                />
                                                <label htmlFor="mobileRadio" className="ms-1" style={{ display: "inline-block", marginBottom: "0.5rem" }}>Mobile No.</label>
                                            </div>
                                        </fieldset>
                                    </div>

                                </div>
                                <div style={{ display: "flex", justifyContent: "center", flexWrap: "wrap", alignItems: "center", margin: "0 0 0.5rem 0" }}>
                                    <input type="text" onChange={(e) => handleRadioChange(e)} value={inputValue} id="YQNum" maxLength="50" placeholder="Enter Search Value." style={{ lineHeight: "1.15", textDecoration: "none", borderStyle: "solid", display: "inline-block", msoHide: "all", backgroundColor: "#ffffff", borderColor: "#0d0d0e", borderRadius: "5px", borderWidth: "1px", color: "#0d0d0e", fontFamily: "Noto Sans, Arial, sans-serif", fontSize: "18px", padding: "10px 5px", width: "428px", maxWidth: "100%", margin: "0 1rem 1rem 1rem" }} />
                                    <div><input type="button" value="TRACK" onClick={() => onTracking()} style={{ backgroundColor: "#41aef3", borderColor: "#41aef3", border: "0", cursor: "pointer", borderRadius: "5px", color: "#ffffff", fontFamily: "Noto Sans, Arial, sans-serif", fontSize: "18px", padding: "0.5rem 1rem", margin: "0 0 1rem 0.5rem" }} /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Container>
                    <Row>
                        <Col>
                            {expandedTracking && (
                                <Card id="track-timeline">
                                    <CardBody>
                                        <div className="d-flex justify-content-between align-items-center flex-wrap mb-3">
                                            <h6 className='my-1'>Tracking Details</h6>
                                            {trackingInfo.length > 0 && (
                                                <>
                                                    <Badge bg="light" color='primary' className="me-2 my-1">AWB Number: <strong>{trackingInfo[0]?.awb_no ? trackingInfo[0]?.awb_no : 'NA'}</strong></Badge>
                                                    {trackingInfo[0]?.lr_no && (
                                                        <Badge bg="light" color='primary' className='my-1'>LR Number: <strong>{trackingInfo[0]?.lr_no ? trackingInfo[0]?.lr_no : 'NA'}</strong></Badge>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center flex-wrap mb-3">
                                            {pod && (
                                                <>
                                                    <Badge bg="light" color='success' className="me-2 my-1"><a href={pod} target='_blank'>Download POD</a></Badge>
                                                </>
                                            )}
                                        </div>
                                        {trackingInfo.length > 0 ? (
                                            <ListGroup variant="flush">
                                                {trackingInfo.map((items, index) => (
                                                    <ListGroupItem key={index}>
                                                        <div>
                                                            <p><Badge bg="light" color={items.status_msg === 'Delivered' ? 'success' : 'primary'} className='my-1'>Status: {items.status_msg}</Badge></p>
                                                            <p>Location: {items.location} ({items.created_at ? moment(items.created_at).format('DD-MM-YYYY HH:mm:ss') : 'NA'})</p>
                                                            <p>Remarks: {items.remarks}</p>
                                                        </div>
                                                        {index !== trackingInfo.length - 1 && <hr />}
                                                    </ListGroupItem>
                                                ))}
                                            </ListGroup>
                                        ) : (
                                            <p className="text-center">Tracking not found</p>
                                        )}
                                    </CardBody>
                                </Card>
                            )}
                        </Col>
                    </Row>
                </Container>
                <footer>
                    <div class="container-fluid px-0 mt-5">
                        <div class="p-3 p-md-4 px-md-5 d-flex flex-wrap justify-content-center justify-content-sm-between align-items-center">
                            <div class="poweredBy-text me-3">
                                Powered By <span id="merchant_name">GL</span>
                            </div>
                            <div class="copyright-text">
                                © Copyright 2024
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </>
    );
}

export default TrackShipment;
